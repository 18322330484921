import { LocalStorageTypes, loadCookiePromocode, setLS } from '@/common/service/storage';
import {
    createContext,
    memo,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { getWishlistLS } from '../../../entities/Wishlist/app/utils/getWishlistLS';
import { trackAddToWishList } from '@/shared/Analytics/GA4';
import { trackWishListAdded, trackWishListRemoved } from '../utils/iterable';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';

type WishlistProviderProps = {
    children: ReactNode;
};

interface IWishlistHandlers {
    addOrRemoveWishlist?: (
        activity: IActivity | IActivityWithAvailability,
        position: number,
        item_list_id: string,
        item_list_name: string
    ) => void;
    fetchWishList?: () => void;
    removeWishListId?: (id: string) => void;
    isActivityInWishlist: (id: string) => boolean;
    isFetching?: boolean;
}

interface IWishlistProps {
    wishlist: string[];
    wishlistCounter: number;
}

type contextProps = IWishlistProps & IWishlistHandlers;

const DEFAULT_WISHLIST: contextProps = {
    wishlist: [],
    wishlistCounter: 0,
    isActivityInWishlist: () => false,
};

const WishListContext = createContext(DEFAULT_WISHLIST);

const WishlistContextProvider = ({ children }: WishlistProviderProps) => {
    const [wishlist, setWishlist] = useState<string[]>([]);
    const [isFetching, setIsFetching] = useState(true);

    const addOrRemoveWishlist = useCallback(
        (
            activity: IActivity | IActivityWithAvailability,
            position: number,
            item_list_id: string,
            item_list_name: string
        ) => {
            const id = activity.id;
            const list = getWishlistLS();
            const parsedList = list.map((wishId) => String(wishId));
            const alreadyInList = parsedList.includes(id);

            const newList = alreadyInList
                ? parsedList?.map((wishId) => String(wishId))?.filter((lsID) => lsID !== id)
                : [...parsedList, id];

            setWishlist(newList);
            setLS(LocalStorageTypes.LS_WISH_LIST, newList);
            const coupon = loadCookiePromocode();

            if (!alreadyInList) {
                trackAddToWishList({
                    currency: 'USD',
                    value: activity.price,
                    items: [
                        {
                            item_id: activity.id,
                            item_name: activity.name,
                            index: position,
                            item_brand: activity.title,
                            item_brand_id: activity.partner_id,
                            item_category:
                                (activity as IActivityWithAvailability).city?.name ||
                                activity.city_name ||
                                '',
                            item_category_id:
                                (activity as IActivityWithAvailability).city?.id ||
                                activity.city_id ||
                                '',
                            item_category2:
                                (activity as IActivityWithAvailability).category?.name ||
                                activity.activity_categories[0].name ||
                                '',
                            item_category2_id:
                                (activity as IActivityWithAvailability).category?.id ||
                                activity.activity_categories[0].id ||
                                '',
                            item_list_id,
                            item_list_name,
                            location_id: activity.google.place_id || '',
                            price: activity.price,
                            quantity: 1,
                            ...(coupon ? { coupon } : {}),
                            ...(activity.price_strike_out
                                ? {
                                      discount:
                                          Math.round(
                                              (activity.price_strike_out - activity.price) * 100
                                          ) / 100,
                                  }
                                : {}),
                        },
                    ],
                });
                trackWishListAdded(activity.id);
            } else {
                // TODO GA4 add remove from wishlist here
                trackWishListRemoved(activity.id);
            }
        },
        []
    );

    const fetchWishList = useCallback(() => {
        return wishlist;
    }, [wishlist]);

    const isActivityInWishlist = useCallback(
        (id: string) => {
            return wishlist.map((id) => String(id)).includes(id);
        },
        [wishlist]
    );

    useEffect(() => {
        const list = getWishlistLS();
        setWishlist(list);
        setIsFetching(false);
    }, []);

    const contextProviderValue = useMemo(
        () => ({
            wishlist,
            wishlistCounter: wishlist?.length ?? 0,
            addOrRemoveWishlist,
            fetchWishList,
            isActivityInWishlist,
            isFetching,
        }),
        [wishlist, addOrRemoveWishlist, fetchWishList, isActivityInWishlist, isFetching]
    );

    return (
        <WishListContext.Provider value={contextProviderValue}>{children}</WishListContext.Provider>
    );
};

export const WishlistProvider = memo(WishlistContextProvider);

export const useWishlistContext = () => {
    const context = useContext(WishListContext);

    if (!context) {
        throw new Error('Context must be used within a ContextProvider');
    }

    return context;
};
