import { createContext, ReactNode, useState, useContext, useCallback, useMemo, memo } from 'react';
import { IPartnersItem } from '@/common/service/api/Partners/Partners.domain';
import { getPartner } from '@/common/service/api/Partners/Partners';

type ProviderProps = {
    children: ReactNode | undefined;
};

type PartnerContextProp = {
    items?: IPartnersItem[];
    onScrollPage: (destination_id: string) => void;
};

const PartnerContext = createContext<PartnerContextProp>({
    onScrollPage: () => {
        return;
    },
});

const PartnerContextProvider = ({ children }: ProviderProps) => {
    const [items, setItems] = useState<IPartnersItem[]>();

    const onScrollPage = useCallback(
        async (destination_id: string) => {
            if (!items?.length) {
                return;
            }
            const { items: newPartnerDetails } = await getPartner({
                destination_id: destination_id,
                offset: 10,
            });

            if (newPartnerDetails) {
                setItems(newPartnerDetails);
            }
        },
        [items?.length]
    );

    const contextProviderValue = useMemo(
        () => ({
            items,
            onScrollPage,
        }),
        [items, onScrollPage]
    );

    return (
        <PartnerContext.Provider value={contextProviderValue}>{children}</PartnerContext.Provider>
    );
};

export const PartnerProvider = memo(PartnerContextProvider);

export const usePartnerContext = () => {
    const context = useContext(PartnerContext);

    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }

    return context;
};
