import {
    getCS,
    saveCS,
    removeCS,
    loadCookiePromocode,
    saveCookiePromocode,
    loadTokenCart,
    saveTokenCart,
} from './CookieStorage';

export {
    getCS,
    saveCS,
    removeCS,
    loadCookiePromocode,
    saveCookiePromocode,
    loadTokenCart,
    saveTokenCart,
};
