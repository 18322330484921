import {
    getCS,
    saveCS,
    loadCookiePromocode,
    saveCookiePromocode,
    loadTokenCart,
    saveTokenCart,
} from './CookieStorage';
import { getLS, setLS, removeLS, clearLS } from './LocalStorage';
import { getSessionStorage, setSessionStorage } from './SessionStorage';

export { getCS, saveCS, loadCookiePromocode, saveCookiePromocode, loadTokenCart, saveTokenCart };
export { getLS, setLS, removeLS, clearLS };
export { getSessionStorage, setSessionStorage };

export * from './Storage.domain';
