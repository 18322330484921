import { IActivityItem } from '@/common/service/api/Activity/Activity.domain';

export const SORT_OPTIONS = {
    bestMatch: 'Best Match',
    highestRated: 'Highest Rated',
    mostPopular: 'Most Popular',
    priceHightToLow: 'Price High to Low',
    priceLowToHight: 'Price Low to High',
};

/* eslint-disable sonarjs/cognitive-complexity */
export const getSortFunction = (
    name: string
): ((prev: IActivityItem, next: IActivityItem) => number) => {
    switch (name) {
        case SORT_OPTIONS.highestRated:
            return (previous, next) => {
                return (
                    Number(next.rating) - Number(previous.rating) ||
                    +next.rate_count - +previous.rate_count
                );
            };
        case SORT_OPTIONS.mostPopular:
            return (previous: IActivityItem, next: IActivityItem) => {
                return Number(previous.total_purchased) > Number(next.total_purchased) ? -1 : 1;
            };
        case SORT_OPTIONS.priceHightToLow:
            return (previous, next) => {
                return previous.price_subunits > next.price_subunits ? -1 : 1;
            };
        case SORT_OPTIONS.priceLowToHight:
            return (previous, next) => {
                return previous.price_subunits > next.price_subunits ? 1 : -1;
            };
        default:
            return (previous, next) => {
                return (previous.search_rank?.total || 0) < (next.search_rank?.total || 0) ? 1 : -1;
            };
    }
};

export const sortByBestMatch = (activities: IActivityItem[], defaultList: string) => {
    const ids = defaultList.split(',');
    return activities
        .reduce((result, activity) => {
            const index = ids.indexOf(activity.id);
            if (index > -1) {
                result[index] = activity;
            }
            return result;
        }, [] as IActivityItem[])
        .filter(Boolean);
};
